<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-lg-5 col-md-12">
            <h2>Settings:</h2>
            <form [formGroup]="form">
                <div class="form-group">
                    <div class="form-check float-right">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="redirectHttps"
                            [checked]="redirectHttps"
                            (change)="checked($event, 'redirectHttps')"
                        >
                        <label
                            class="form-check-label"
                            for="redirectHttps"
                        >Redirect to https</label>
                    </div>
                    <label for="domain">Domain</label>
                    <input
                        type="text"
                        class="form-control"
                        id="domain"
                        formControlName="domain"
                    >
                </div>
                <div class="form-group">
                    <div class="form-check float-right">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="autoappend"
                            [checked]="autoAppend"
                            (change)="checked($event, 'autoAppend')"
                        >
                        <label
                            class="form-check-label"
                            for="autoappend"
                        >Append /public to symlink</label>
                    </div>
                    <label for="symlink">root (symlink)</label>
                    <input
                        type="text"
                        class="form-control"
                        id="symlink"
                        formControlName="symlink"
                    >
                </div>
                <div class="form-group">
                    <label for="root">source</label>
                    <input
                        type="text"
                        class="form-control"
                        id="root"
                        formControlName="root"
                    >
                </div>
                <div class="form-group">
                    <label for="apache">config location</label>
                    <input
                        type="text"
                        class="form-control"
                        id="apache"
                        formControlName="apache"
                    >
                </div>
            </form>

            <footer>
                <a href="https://gitlab.com/RoobieBoobieee/apache-conf-generator">Open source</a>
                tool made by
                <a href="https://robvankeilegom.be">
                    Rob Van Keilegom.
                </a>
            </footer>
        </div>

        <div class="col-lg-7 col-md-12">
            <h2>
                Step 1:

                <button
                    class="btn clipboard-button umami--click--step-1-copy"
                    data-clipboard-target="#txthosts"
                >
                    <img
                        src=""
                        alt="Copy"
                    >
                </button>
            </h2>
            <pre id="txthosts">{{ this.hostFile }}</pre>

            <h2>
                Step 2:

                <button
                    class="btn clipboard-button umami--click-step-2-copy"
                    data-clipboard-target="#txtsymlink"
                >
                    <img
                        src=""
                        alt="Copy"
                    >
                </button>
            </h2>
            <pre id="txtsymlink">{{ this.symlink }}</pre>
            <h2>
                Step 3:

                <button
                    class="btn clipboard-button umami--click-step-3-copy"
                    data-clipboard-target="#txtvhost"
                >
                    <img
                        src=""
                        alt="Copy"
                    >
                </button>
            </h2>
            <pre
                id="txtvhost"
                #codeEl
                class="col-12"
            >
      </pre>
            <h2>
                Step 4:

                <button
                    class="btn clipboard-button umami--click-step-4-copy"
                    data-clipboard-target="#txtenable"
                >
                    <img
                        src=""
                        alt="Copy"
                    >
                </button>
            </h2>
            <pre id="txtenable">{{ this.enableConfig }}</pre>
        </div>
    </div>
</div>
